import React from "react";

const AddTestsCTA = () => {
  return (
    <center>
      <p>No tests have been added yet. Start by creating one.</p>
    </center>
  );
};

export default AddTestsCTA;
